var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 mt-6 data-table-collapse",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":50,"item-class":_vm.highlightAuthorizedStudent,"hide-default-header":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[(_vm.headerGroup)?_c('tr',_vm._l((props.headers),function(head){return _c('th',{staticClass:"text-center data-table-header-border",style:({display: head.groupDispay, width: head.groupWidth}),attrs:{"role":"columnheader","rowspan":head.groupRowspan,"colspan":head.groupColspan,"scope":"col"}},[(head.value === 'markTotal')?_c('span',{staticClass:"highlight",domProps:{"innerHTML":_vm._s(head.groupText)}}):_c('span',{domProps:{"innerHTML":_vm._s(head.groupText)}})])}),0):_vm._e(),_c('tr',_vm._l((props.headers),function(head){return _c('th',{staticClass:"text-center data-table-header-border",style:({display:head.display}),attrs:{"role":"columnheader","scope":"col"}},[(head.value === 'totalRating_1')?_c('span',{staticClass:"highlight",domProps:{"innerHTML":_vm._s(head.text)}}):(head.value === 'totalRating_2')?_c('span',{staticClass:"highlight",domProps:{"innerHTML":_vm._s(head.text)}}):(head.value === 'totalRating_3')?_c('span',{staticClass:"highlight",domProps:{"innerHTML":_vm._s(head.text)}}):_c('span',{domProps:{"innerHTML":_vm._s(head.text)}})])}),0)])]}},{key:"header.markTotal",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"highlight"},[_vm._v(_vm._s(header.markTotal))])]}},{key:"item.totalRating_1",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"highlight"},[_vm._v(_vm._s(item.totalRating_1))])]}},{key:"item.totalRating_2",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"highlight"},[_vm._v(_vm._s(item.totalRating_2))])]}},{key:"item.totalRating_3",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"highlight"},[_vm._v(_vm._s(item.totalRating_3))])]}},{key:"item.markTotal",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"highlight"},[_vm._v(_vm._s(item.markTotal))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="50"
        class="elevation-1 mt-6 data-table-collapse"
        :item-class="highlightAuthorizedStudent"
        hide-default-header
        dense
    >
        <template v-slot:header="{ props }">
            <thead class="v-data-table-header">
            <tr v-if="headerGroup">
                <th class="text-center data-table-header-border"
                    role="columnheader"
                    :rowspan="head.groupRowspan"
                    :colspan="head.groupColspan"
                    :style="{display: head.groupDispay, width: head.groupWidth}"
                    scope="col"
                    v-for="head in props.headers">
                    <span v-if="head.value === 'markTotal'" v-html="head.groupText" class="highlight"></span>
                    <span v-else v-html="head.groupText"></span>
                </th>
            </tr>
            <tr>
                <th class="text-center data-table-header-border"
                    role="columnheader"
                    :style="{display:head.display}"
                    scope="col"
                    v-for="head in props.headers">
                    <span v-if="head.value === 'totalRating_1'" v-html="head.text" class="highlight"></span>
                    <span v-else-if="head.value === 'totalRating_2'" v-html="head.text" class="highlight"></span>
                    <span v-else-if="head.value === 'totalRating_3'" v-html="head.text" class="highlight"></span>
                    <span v-else v-html="head.text"></span>
                </th>
            </tr>

            </thead>
        </template>
        <template v-slot:header.markTotal="{ header }">
            <div class="highlight">{{ header.markTotal }}</div>
        </template>

        <!--            Сделать шрифт жирным в колонках итоговых оценках по кт и тога-->
        <template v-slot:item.totalRating_1="{ item }">
            <div class="highlight">{{ item.totalRating_1 }}</div>
        </template>
        <template v-slot:item.totalRating_2="{ item }">
            <div class="highlight">{{ item.totalRating_2 }}</div>
        </template>
        <template v-slot:item.totalRating_3="{ item }">
            <div class="highlight">{{ item.totalRating_3 }}</div>
        </template>
        <template v-slot:item.markTotal="{ item }">
            <div class="highlight">{{ item.markTotal }}</div>
        </template>
    </v-data-table>
</template>

<script>
    export default {
        name: "VedTable",
        props: {
            headers: Array,
            items: Array,
            headerGroup: Boolean,
            studentID: Number
        },
        mounted() {
          console.log(this.headers)
          console.log(this.items)
        },
        methods: {
            highlightAuthorizedStudent(item){
                if (this.studentID === item.studentID){
                    return "highlight-auth-student"
                }
            }
        }
    }
</script>

<style scoped>
    .data-table-header-border{
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    /* TODO из-за того, что применен колапс, при наведении на 1 пискель в право сдвинут левый бок строки. Исправить. */
    .data-table-collapse >>> table{
        border-collapse: collapse;
    }

</style>
<style>
    .highlight-auth-student{
        background-color: #c5eea1 !important;
    }
    .highlight{
        font-weight: bold;
        color: rgba(0, 0, 0, 0.75);
    }
</style>
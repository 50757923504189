<template>


  <v-container>
         <v-card class="mb-3">
        <v-card-title>
          <v-icon class="mr-2">mdi-file-document-edit</v-icon> 
            Пользовательское cоглашение
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card>
      <v-card class="px-5 mt-9">
    <v-row>
      <v-col>
        <p>Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между (далее Электронная информационно-образовательная среда ДГТУ или Администрация) с одной стороны и пользователем сайта с другой.
Сайт Электронная информационно-образовательная среда ДГТУ не является средством массовой информации.</p>
        <p>Используя сайт, Вы соглашаетесь с условиями данного соглашения. <b>Если Вы не согласны с условиями данного соглашения, не используйте сайт Электронная информационно-образовательная среда ДГТУ!</b></p>
      </v-col>
    </v-row>
      <v-row>
      <v-col>
        <h3>Предмет соглашения</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p><b>Администрация предоставляет пользователю право на размещение на сайте следующей информации:</b></p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Предмет соглашения</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p><b>Администрация предоставляет пользователю право на размещение на сайте следующей информации:</b></p>
        <ul>
          <li>Текстовой информации</li>
          <li>Ссылок на материалы, размещенные на других сайтах</li>
        </ul>
      </v-col>
    </v-row>
    </v-row>
        <v-row>
      <v-col>
        <h3>Права и обязанности сторон</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p><b>Пользователь имеет право:</b></p>
        <ul class="mb-3">
          <li>осуществлять поиск информации на сайте</li>
          <li>получать информацию на сайте</li>
          <li>создавать информацию для сайта</li>
          <li>использовать информацию сайта в личных некоммерческих целях</li>
        </ul>
        <p><b>Администрация имеет право:</b></p>
        <ul class="mb-3">
          <li>по своему усмотрению и необходимости создавать, изменять, отменять правила</li>
          <li>ограничивать доступ к любой информации на сайте</li>
          <li>создавать, изменять, удалять информацию</li>
          <li>удалять учетные записи</li>
        </ul>
        <p><b>Пользователь обязуется:</b></p>
        <ul class="mb-3">
          <li>обеспечить достоверность предоставляемой информации</li>
          <li>обеспечивать сохранность личных данных от доступа третьих лиц</li>
          <li>обновлять Персональные данные, предоставленные при регистрации, в случае их изменения</li>
          <li>не нарушать работоспособность сайта</li>
          <li>не совершать действия, направленные на введение других Пользователей в заблуждение</li>
          <li>не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим лицам</li>
          <li>не регистрировать учетную запись от имени или вместо другого лица за исключением случаев, предусмотренных законодательством РФ</li>
          <li>не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сайтом и его Сервисами</li>
        </ul>
        <p><b>Администрация обязуется:</b></p>
        <ul class="mb-3">
          <li>поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от Администрации причинам.</li>
          <li>осуществлять разностороннюю защиту учетной записи Пользователя</li>
        </ul>
      </v-col>
    </v-row>
        <v-row>
      <v-col>
        <h3>Условия действия Соглашения</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>Данное Соглашение вступает в силу при любом использовании данного сайта.</p>
        <p>Соглашение действует бессрочно.</p>
        <p>Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.</p>
        <p>Администрация не оповещает пользователей об изменении в Соглашении.</p>
      </v-col>
    </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "TermsOfUse",
  data () {
    return {

    }
  },
  mounted() {
    
  },
  computed: {

  },
  methods: {
   
  }
}
</script>

<style scoped>
    
ul {
    list-style: none;
}

ul li:before {
    content:  "—";
    position: relative;
    left:     -5px;
}

    li {
      font-family: Georgia, 'Times New Roman', Times, serif;
   }

   h2{
     font-family: Georgia, 'Times New Roman', Times, serif;
   }

   h3{
     font-family: Georgia, 'Times New Roman', Times, serif;
   }

   p{
     font-family: Georgia, 'Times New Roman', Times, serif;
   }

</style>
<template>
  <div style="max-width: 1000px;" class="ma-auto mt-3">
    <vue-headful :title="'Оценки'"/>
    <v-layout style="max-width: 100%;">
      <v-btn class="align-self-center" small outlined style="text-transform: inherit;padding: 2px 8px;font-size: 14px;" @click="$router.go(-1)" color="#004992">
        <v-icon left>mdi-arrow-left-circle-outline</v-icon>
        Назад
      </v-btn>
      <v-card-title class="pl-3">
        Оценки
      </v-card-title>
      <v-spacer></v-spacer>
      <v-slide-x-transition>
        <v-text-field
            v-if="!groupID"
            @blur="saveFilter"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
            solo
            class="mb-3 search-field"
            clearable
        ></v-text-field>
      </v-slide-x-transition>
    </v-layout>
    <v-slide-x-transition>
      <div v-if="!groupID && veds">
        <v-layout  class="mb-3">
          <v-card style="width: 100%;" class="pa-3">
            <year-picker v-model="year"></year-picker>
          </v-card>
        </v-layout>

        <v-card>
          <v-data-table :page.sync="page" :items-per-page="15" :loading="loading" :search="search" :items="groups" :headers="groupsHeaders">
            <template v-slot:item.groupName="{ item }">
              <a style="white-space: nowrap;" :href='`#/Veds/${item.groupID}`'>
                <v-icon left>mdi-link</v-icon>
                {{item.groupName}}
              </a>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div v-else-if="group">
        <v-card style="width: 100%;" class="pa-3 mb-3">
          <v-layout>
            <v-chip small v-if="group" to="/Veds">
              <v-icon left>mdi-chevron-left</v-icon>
              {{group.groupName}}
            </v-chip>
            <sem-picker v-model="sem" class="ml-3"></sem-picker>
                  <v-spacer></v-spacer>
            <v-chip small v-if="group" :href='`#/SummaryVeds/${groupID}`'>
              <v-icon left small>mdi-notebook-multiple</v-icon>
                  Сводные оценки
              </v-chip>
          </v-layout>
        </v-card>
        <v-card>
          <v-data-table v-if="veds" hide-default-footer disable-pagination :items-per-page="15" :loading="loading" :items="veds.filter(f=> f.sem % 2 === sem)" :headers="vedsHeaders">
            <template v-slot:item.dis="{ item }">
              <a :href='`#/Veds/${groupID}/${item.ratingID}`'>
                <v-icon left>mdi-link</v-icon>
                {{item.dis}}
              </a>
            </template>
            <template v-slot:item.lastDateSave="{ value }">
              {{new Date(value).toLocaleDateString('ru-RU')}}
            </template>
            <template v-slot:item.isClosed="{ value }">
              <v-checkbox hide-details readonly :value="value" class="mt-0 pt-0"></v-checkbox>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-layout class="pa-3">
            Всего ведомостей - {{veds.filter(f=> f.sem % 2 === sem).length}}
          </v-layout>
        </v-card>
      </div>
      
    </v-slide-x-transition>

  </div>
</template>

<script>
import SemPicker from "@/components/SemPicker";
let arrayUnique = function (a) {
  return a.reduce(function (p, c) {
    if (p.indexOf(c) < 0) p.push(c);
    return p;
  }, []);
};
import veds from "@/helpers/api/veds";
import directory from "@/helpers/api/directory";
import common from "@/helpers/common";
import YearPicker from "@/components/YearPicker";
export default {
  name: "VedsList",
  components: {SemPicker, YearPicker},
  data () {
    return {
      veds: [],
      groups: [],
      search: null,
      page: 1,
      loading: true,
      group: null,
      sem: common.getCurrentSem(),
      year: common.getCurrentYear()
    }
  },
  watch: {
    year () {
      this.loadGroups()
    },
    groupID () {
      if(this.groupID) {
        this.loadVeds()
      } else {
        this.group = null
        if(this.groups.length === 0) {
          this.loadGroups()
        }
        this.veds = []
      }
    }
  },
  computed: {
    sems () {
      return arrayUnique(this.veds.map(m=>m.sem % 2))
    },
    groupID () {
      return this.$route.params.groupID
    },
    groupsHeaders () {
      return [
        {text: 'Группа', value: 'groupName'},
        {text: 'Курс', value: 'course'},
        {text: 'Факультет', value: 'faculty'},
        {text: 'Специальность', value: 'speciality'},
      ]
    },
    vedsHeaders () {
      return [
        {text: 'Дисциплина', value: 'dis'},
        {text: 'Преподаватель', value: 'teacher'},
        {text: 'Тип', value: 'type'},
        {text: 'Дата сохранения', value: 'lastDateSave'},
        {text: 'Закрыта', value: 'isClosed'}
      ]
    }
  },
  mounted() {
    if(!this.groupID) {
      this.loadGroups()
    } else {
      this.loadVeds()
    }

  },
  methods: {
    savePage(e) {
      sessionStorage.setItem('plansListPage', e)
    },
    saveFilter() {
      if(this.search) {
        sessionStorage.setItem('vedsListGroupFilter', this.search)
      } else {
        sessionStorage.setItem('plansListFilter', '')
      }
    },
    async loadVeds () {
      this.loading = true
      this.veds = await veds.getVeds(this.groupID)
      this.group = (await directory.getGroups(null, null, this.groupID))[0]
      this.loading = false
    },
    async loadGroups () {
      this.loading = true
      this.groups =  await directory.getGroups(null, null, null, this.year)
      this.loading = false
    }
  }
}
</script>

<style>
</style>

<template>
  <div class="ma-auto mt-3 px-3">
    <v-layout style="max-width: 100%;">
      <v-card-title class="pl-3">
        Ведомость учета успеваемости студентов по рейтингу
      </v-card-title>
      <v-spacer></v-spacer>
    </v-layout>
    <v-card class="pa-3">
      <v-layout>
        <v-chip class="mr-3" @click="$router.back()" small> <v-icon left>mdi-chevron-left</v-icon> Назад</v-chip>
        <span v-if="dataVed === null">
          Не удалось загрузить ведомости :(
        </span>
      </v-layout>
    </v-card>
<!--    Титульная шапка (статус, тип, группа, кафедра, дисциплина и т.д.) -->
<!--    TODO: Сделать колонки одной высотой-->
    <v-row v-if="dataVed !== null" class="mt-0">
<!--    Левая колонка титулки -->
      <v-col class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <v-card class="mt-3 d-flex">
            <v-list dense width="100%">
            <v-list-item>
              <v-list-item-content><b>Статус</b></v-list-item-content>
              <v-list-item-content class="align-end">
              {{dataVed.header.closed ? "Открыта" : "Закрыта"}}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content><b>Тип</b></v-list-item-content>
              <v-list-item-content class="align-end">
                {{dataVed.header.type}}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content><b>Группа</b></v-list-item-content>
              <v-list-item-content class="align-end">
                {{dataVed.header.group}}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="overflow-x-hidden">
            <v-list-item-content><b>Кафедра</b></v-list-item-content>
            <v-list-item-content>
              {{dataVed.header.cathedra}}
            </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content><b>Дисциплина</b></v-list-item-content>
              <v-list-item-content class="overflow-x-hidden text-no-wrap">
                {{dataVed.header.discipline}}
              </v-list-item-content>
            </v-list-item>
            </v-list>
        </v-card>
      </v-col>
<!--    Центральная колонка титулки -->
      <v-col class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <v-card class="mt-lg-3 mt-md-3 mt-sm-0 mt-xs-0">
          <v-list dense >
          <v-list-item>
            <v-list-item-content><b>Преподаватель</b></v-list-item-content>
            <v-list-item-content class="align-end">
              {{dataVed.header.teacher}}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content><b>Курс</b></v-list-item-content>
            <v-list-item-content class="align-end">
              {{dataVed.header.course}}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content><b>Год контроля</b></v-list-item-content>
            <v-list-item-content class="align-end">
              {{dataVed.header.year}}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content><b>Блок</b></v-list-item-content>
            <v-list-item-content class="align-end">
              {{dataVed.header.cycle}}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content><b>Учебный план</b></v-list-item-content>
            <v-list-item-content class="align-end">
              {{dataVed.header.planName}}
            </v-list-item-content>
          </v-list-item>
          </v-list>
        </v-card>
      </v-col>
<!--    Правая колонка титулки -->
      <v-col class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <v-card class="mt-lg-3 mt-md-3 mt-sm-0 mt-xs-0">
          <v-list dense>
            <v-list-item>
              <v-list-item-content><b>Часов</b></v-list-item-content>
              <v-list-item-content class="align-end">
                {{dataVed.header.hours}}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content><b>Семестр</b></v-list-item-content>
              <v-list-item-content class="align-end">
                {{dataVed.header.semester}}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content><b>Дата изменения</b></v-list-item-content>
              <v-list-item-content class="align-end">
                {{titleLastSaved}}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content><b>Компьютер</b></v-list-item-content>
              <v-list-item-content class="align-end">
                {{dataVed.header.lastPC}}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content><b>Сохранений</b></v-list-item-content>
              <v-list-item-content class="align-end">
                {{dataVed.header.countSaves}}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

<!--    Главная таблица -->
    <div  v-if="dataVed !== null">
<!-- Если текущий тип контроля экзамен (1) или зачет (2)-->
        <template v-if="(vedType === 1) || (vedType === 2)">
            <!--      Включение/выключение контрольных точек-->
            <v-chip
                    class="mt-6 mr-3"
                    filter
                    @click="$set(drawControlPoints, index, !cp)"
                    :input-value="cp"
                    :key="index"
                    v-for="(cp, index) in drawControlPoints"

            >
                Контрольная точка {{index + 1}}
            </v-chip>
          <ved-table
                     :header-group="true"
                     :headers="headersExam"
                     :items="dataExam"
                     :studentID="this.dataVed.header.studentID"
          ></ved-table>
        </template>
<!-- Если текущий тип контроля курсовая работа (3)-->
        <ved-table v-else-if="vedType === 3"
               :headers="headersCourseWork"
               :items="dataCourseWork"
        ></ved-table>
<!-- Если текущий тип контроля практика (6)-->
        <ved-table v-else-if="vedType === 6"
                   :headers="headersPractice"
                   :items="dataPractice"
        ></ved-table>
<!-- Если текущий тип контроля любой (default). В соответсвии с ModernStud.-->
        <ved-table v-else
                   :headers="headersPractice"
                   :items="dataPractice"
        ></ved-table>
    </div>
  </div>
</template>

<script>
// TODO что там с совместимостью с главным ДГТУ?

import veds from "@/helpers/api/veds";
import VedTable from "@/components/VedTable";
export default {
  name: "VedsOpen",
  data(){
    return{
      dataVed: null,
      drawControlPoints: []
    }
  },
  components: {
      VedTable
  },
  mounted() {
    this.loadVed()
  },
  computed: {
    vedType(){
      // Тип ведомости изменен в соотсветсвии с проектом ModernStud
      // На "рейтинговую" ведомость
        if ((this.dataVed.controlPointsHeader !== null) && (this.dataVed.controlPointsHeader !== undefined)){
          if ((this.dataVed.controlPointsHeader.length === 0) && (this.dataVed.header.vedType !== 3)){
            return 6
          }
        }
      return this.dataVed.header.vedType
    },
    vedID(){
      return this.$route.params.vedID
    },
    titleLastSaved(){
      let date = new Date();
      date.setTime(Date.parse(this.dataVed.header.dateLastSaved))
      console.log(date);
      return date.getDate()+"."+(date.getMonth()+1)+"."+date.getFullYear()
    },
    titleExamDate(){
      let date = new Date();
      date.setTime(Date.parse(this.dataVed.header.dateControl))
      console.log(date);
      return date.getDate()+"."+(date.getMonth()+1)+"."+date.getFullYear()
    },
    headerRetake(){
      // Пересдачи
      let retake = []
      // TODO реализовать общую шапку для даты и результата
      // eslint-disable-next-line no-unused-vars
      let retakeText = ["Первая пересдача", "Вторая пересдача", "Третья пересдача"]
      for (let i = 1; i <= 3; i++){

        // Если присуствуют пересдачи
        if (this.dataVed.marks.filter(elem=>elem["dateRetake"+i] !== null).length > 0){
          let currentRetakeHeaederTemplate = [
            {
                text: "Дата",
                value: "dateRetake"+i,
                groupText: retakeText[i-1],
                groupColspan: 2,
                align: "center"
            },
            {
                text: 'Рейтинг',
                value: 'retake'+i,
                groupDispay: "none",
                align: "center"
            }
          ]
          retake = [...retake, ...currentRetakeHeaederTemplate]
        }
      }
      return retake
    },
    headersExam() {
      //контрольные точки
      let cpsHeader = []
      this.dataVed.controlPointsHeader.forEach((elem)=>{
          console.log("Cp #"+elem.controlPointNo)
          if(this.drawControlPoints[elem.controlPointNo - 1] === false){
              return
          }
        let cpHeaderTemplate = [
          {
              text: 'Лек. '+elem.weightLecture+"%",
              value: 'ratingByLection_'+elem.controlPointNo,
              groupText: "Вес точки "+elem.weight+"%",
              groupColspan: 5,
              align: "center"
          },
          {
              text: 'Пр. '+elem.weightPractic+"%",
              value: 'ratingByPractices_'+elem.controlPointNo,
              groupDispay: "none",
              align: "center"},
          {
              text: 'Лаб. '+elem.weightLab+"%",
              value: 'ratingByLaboratoryWorks_'+elem.controlPointNo,
              groupDispay: "none",
              align: "center"},
          {
              text: 'Др. '+elem.weightOther+"%",
              value: 'ratingByOthers_'+elem.controlPointNo,
              groupDispay: "none",
              align: "center"
          },
          {
              text: 'Итоги<br>по<br>КТ '+elem.controlPointNo,
              value: 'totalRating_'+elem.controlPointNo,
              groupDispay: "none",
              align: "center"
          },
        ]
        cpsHeader = [...cpsHeader, ...cpHeaderTemplate]
      })
      return [
        {
            groupText: '№',
            groupWidth: "50px",
            value: 'numberInVed',
            groupRowspan: 2,
            display: "none",
            align: "center"},
        {
            groupText: 'Номер зачетной <br>книжки',
            groupWidth: "150px",
            value: 'numberSatisfactionBook',
            groupRowspan: 2,
            display: "none"
        },
        ...cpsHeader,
        {
            groupText: 'Надбавка %',
            value: 'premium',
            groupRowspan: 2,
            display: "none",
            align: "center"
        },
        {
            groupText: 'Итоговый рейтинг по КТ',
            value: 'totalPercent',
            groupRowspan: 2,
            display: "none",
            align: "center"
        },
        {
            groupText: 'Оценка по рейтингу',
            value: 'markByRating',
            groupRowspan: 2,
            display: "none",
            align: "center"
        },
        {
            groupText: 'Итоги',
            value: 'markTotal',
            groupRowspan: 2,
            display: "none",
            align: "center"
        },
        {
            groupText: this.titleExamDate,
            text: ' Экзамен',
            value: 'markByExam',
            align: "center"
        },
        ...this.headerRetake
      ]
    },
    dataExam(){
      //  TODO Возможно это можно сделать в самих запросах, пересмотреть
      let listFinalyData = [...this.dataVed.marks];
      listFinalyData.forEach((svm, index, svmArray)=>{
        // Выбираем все доступные КТ для студента
        let cpArray = this.dataVed.controlPoints.filter(cp => cp.studentID === svm.studentID)
        cpArray.forEach(mark=>{
          // Переименовываем столбики, чтобы небыло коллизий
          Object.keys(mark).forEach(markKey=>{
            svmArray[index][markKey+"_"+mark.numberCP] = mark[markKey]
          })
        })
      })
      listFinalyData = listFinalyData.map((row)=>{
          let nextRow = {}
          Object.keys(row).forEach(rowKey=>{
              if (row[rowKey] === 0){
                  nextRow[rowKey] = ""
              }else{
                  nextRow[rowKey] = row[rowKey]
              }
          })
          return nextRow
      })
      return listFinalyData
    },
    headersCourseWork() {
      return [
        {text: '№', width: "50px", value: 'numberInVed', fixed: true},
        {text: 'Номер зачетной книжки', width: "100px", value: 'numberSatisfactionBook', fixed: true},
        {text: 'Дата сдачи', value: 'deliveryDate'},
        {text: 'Оценка', value: 'markTotal'},
        {text: 'Тема курсовой работы', value: 'themeCourseWork'},
        ...this.headerRetake
      ]
    },
    dataCourseWork(){
      let listFinalyData = [...this.dataVed.marks];
      return listFinalyData
    },
    headersPractice() {
      return [
        {text: '№', width: "50px", value: 'numberInVed', fixed: true},
        {text: 'Номер зачетной книжки', width: "100px", value: 'numberSatisfactionBook', fixed: true},
        {text: 'Дата сдачи', value: 'deliveryDate'},
        {text: 'Оценка', value: 'markTotal'},
        ...this.headerRetake
      ]
    },
    dataPractice(){
      let listFinalyData = [...this.dataVed.marks];
      return listFinalyData
    }

  },
  methods: {
    async loadVed(){
       await veds.getVed(this.vedID).then((data)=>{
        console.log("Data ved", data.data);
         this.dataVed = data.data;
       this.drawControlPointChip()
      })
    },
    drawControlPointChip(){
        // Для переключения отображения контрольных точек
        if (this.dataVed.controlPointsHeader !== null){
            this.dataVed.controlPointsHeader.forEach(()=>{
                this.drawControlPoints.push(true)
            })
        }
    },
    hideShowControlPoint(index){
        console.log("Hide/show CP", index)

        console.log(this.drawControlPoints[index])
    }
  }
}
</script>

<style scoped>

</style>
/*
    Методы для работы с API ведомостями
*/
import api from '@/helpers/apiGet'
export default {
    async getVeds (groupID) {
        let response = await api.get('/api/Ved/List', { groupID: groupID })
        return response.data.veds
    },

    async getStatVeds ( year, sem, mode) {
        let response = await api.get('/api/Ved/Stat/StatByMode', { year: year, sem: sem, mode: mode })
        return response.data.vedList
    },

    async getStatDiscipline ( year, sem, mode,stract,id) {
        let response = await api.get('/api/Ved/Stat/Discipline', { year: year, sem: sem, mode: mode, stract:stract, id:id})
        return response.data
    },

    async DebtStatistics ( typeStatic, facId, year, sem, kafId, orderBy) {
        let response = await api.get('/api/Ved/Stat/DebtStatistics', { typeStatic:typeStatic, facId:facId, year: year, sem: sem, kafId:kafId, orderBy:orderBy })
        return response.data
    },

    async DebtStatisticsByStudent ( groupID, sem, mode) {
        let response = await api.get('/api/Ved/Stat/DebtStatisticsByStudent', { groupId : groupID, sem: sem, mode: mode})
        return response.data
    },

    async DebtByStudent ( groupID, sem, stud) {
        let response = await api.get('/api/StudentsDebts/DebtByStudent', { groupId : groupID, sem: sem, stud: stud})
        return response.data
    },
    async getVed(vedID){
        return await api.get('/api/Ved/Vedbox', {vedID: vedID})
    }
}
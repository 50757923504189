<template>
  <div>
    <span class="mr-2">
    Семестр:
    </span>
    <v-menu class="ml-2" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-chip v-bind="attrs" v-on="on" small>
          <span>
            {{items.filter(f=>f.value === value)[0].text}}
          </span>
          <v-icon right>mdi-chevron-down</v-icon>
        </v-chip>
      </template>
      <v-list>
        <v-list-item
            @click="$emit('input', item.value)"
            v-for="(item, index) in items"
            :key="index"
            :disabled="value === item.value"
        >
          <v-list-item-title>
            <v-icon small class="mr-2" style="top: -2px;">{{item.icon}}</v-icon>
            {{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "SemPicker",
  props: {
    value: Number
  },
  data () {
    return {
      items: [
        {value: 1, text: 'Осень', icon: 'mdi-leaf'},
        {value: 0, text: 'Весна', icon: 'mdi-flower-tulip'},
      ]
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-container>
    <v-row justify="center">
      <v-col md="auto"><h2 class="text--primary">Контакты и реквизиты</h2></v-col>
    </v-row>
    <v-row>
      <v-col>
        <h4 class="text--primary">По общим техническим вопросам: отдел обеспечения учебного процесса</h4>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col md="auto">
            <v-chip
              color="primary"
              outlined
              pill
            >
              <v-icon left class="mr-2 ml-1">
                mdi-door-open
              </v-icon>
              <b class="mr-2">аудитория:</b> <v-span>8-201</v-span>
            </v-chip>
      </v-col>
      <v-col md="auto">
            <v-chip
              color="primary"
              outlined
              pill
            >
              <v-icon left class="mr-2 ml-1">
                mdi-phone-in-talk
              </v-icon>
              <b class="mr-2">телефон:</b> <v-span> +7 (863) 2-738-529 (25-29)</v-span>
            </v-chip>
      </v-col>
      <v-col md="auto">
            <v-chip
              color="primary"
              outlined
              pill
            >
              <v-icon left class="mr-2 ml-1">
                mdi-card-account-mail
              </v-icon>
              <b class="mr-2">е-mail:</b> <v-span> ooup@donstu.ru</v-span>
            </v-chip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h4 class="text--primary">Реквизиты образовательного учреждения:</h4>
      </v-col>
    </v-row>
        <v-row>
      <v-col md="auto">
        <v-simple-table dense>
            <tbody>
              <tr>
                <td class="requisite-name text--primary">Название</td>
                <td style="max-width:500px;">ФЕДЕРАЛЬНОЕ ГОСУДАРСТВЕННОЕ БЮДЖЕТНОЕ ОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ВЫСШЕГО ОБРАЗОВАНИЯ "ДОНСКОЙ ГОСУДАРСТВЕННЫЙ ТЕХНИЧЕСКИЙ УНИВЕРСИТЕТ" (ДГТУ)</td>
              </tr>
              <tr>
                <td class="requisite-name text--primary">ИНН</td>
                <td>6165033136</td>
              </tr>
              <tr>
                <td class="requisite-name text--primary">КПП</td>
                <td>616501001</td>
              </tr>
              <tr>
                <td class="requisite-name text--primary" >УФК по Ростовской области</td>
                <td>(5800, ДГТУ л/с 20586У31690)</td>
              </tr>
              <tr>
                <td class="requisite-name text--primary">Отделение</td>
                <td>Ростов–на-Дону</td>
              </tr>
              <tr>
                <td class="requisite-name text--primary">р/сч.</td>
                <td>40501810260152000001</td>
              </tr>
              <tr>
                <td class="requisite-name text--primary">БИК</td>
                <td>046015001</td>
              </tr>
              <tr>
                <td class="requisite-name text--primary" >КБК</td>
                <td>00000000000000000130 </td>
              </tr>
              <tr>
                <td class="requisite-name text--primary">ОКТМО</td>
                <td>60701000</td>
              </tr>
              <tr>
                <td class="requisite-name text--primary">ОГРН</td>
                <td>1026103727847</td>
              </tr>
            </tbody>
          </v-simple-table>
      </v-col>
    </v-row>
        <v-row>
      <v-col>
        <h4 class="text--primary">Информация о месте нахождения образовательной организации:</h4>
      </v-col>
    </v-row>
        <v-row>
      <v-col md="auto">
        <v-simple-table dense>
            <tbody>
              <tr>
                <td class="requisite-name text--primary">Юридический адрес:</td>
                <td style="max-width:500px;">344003, ЮФО, Ростовская область, г.Ростов-на-Дону, пл. Гагарина, 1</td>
              </tr>
              <tr>
                <td class="requisite-name text--primary">Фактический адрес:</td>
                <td style="max-width:500px;">344003, ЮФО, Ростовская область, г.Ростов-на-Дону, пл. Гагарина, 1</td>
              </tr>
            </tbody>
          </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="auto">
          <v-card
            elevation="2"
            class="px-2 py-2"
            :href='`#/PersonalDataProcessingPolicy`'
          >
            <v-icon class="mr-2">mdi-file-account</v-icon>
            <v-span >Политика в отношении обработки персональных данных</v-span>
          </v-card>
      </v-col>
      <v-col md="auto">
          <v-card
            elevation="2"
            class="px-2 py-2"
            :href='`#/TermsOfUse`'
          >
            <v-icon class="mr-2">mdi-file-document-edit</v-icon>
            <v-span >Пользовательское cоглашение</v-span>
          </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Requisites",
  data () {
    return {

    }
  },
  mounted() {
    
  },
  computed: {

  },
  methods: {
   
  }
}
</script>

<style scoped>
.requisite-name{
  font-weight: 600;
}
</style>